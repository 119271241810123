import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-name"
export default class extends Controller {
  connect() {
    if (this.element.hasAttribute("data-file")) {
      this.element.closest(".code-toolbar").classList.add("has-file-name")
    }
  }
}
